<template>
  <div :class="`bg-white d-flex flex-column ${isWide ? 'px-30 py-20' : 'p-10'}`">
    <span :class="`font-bold color-pink center ${isMobile ? 'font-15' : 'font-20'}`">EVENTBOX</span>
    <span :class="`font-bold center ${isMobile ? 'mt-3 font-25' : 'mt-5 font-40'}`">{{ $t('cookie_policy') }}</span>
    <span :class="`center ${isMobile ? 'mt-5 font-20' : 'mt-10 font-30'}`">{{ $t('about_header') }}</span>
    <img class="w-100 my-10" :src="assets.cookie_policy_img" />
    <span :class="`font-bold ${isMobile ? 'mt-10 font-25' : 'mx-20 mt-15 font-35'}`">{{ $t('our_cookie_policy') }}</span>
    <span :class="`${isMobile ? 'my-5 font-20' : 'mx-20 my-10 font-30'}`" v-html="$t('cookie_description')"></span>
  </div>
</template>

<script>
import cookie_policy_img from '@/assets/images/cookie_policy_img.png';

export default {
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  },
  data() {
    return {
      assets: {
        cookie_policy_img
      }
    };
  }
};
</script>